import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
} from 'react';
import * as React from 'react';
import { init } from '@feathery/react';

type FeatheryFormContextProps = {
  currentFormName: null | string;
  isFeatheryFormModalOpen: boolean;
  initialValues: Record<string, string>;
  emailList: null | string;
  vendorName: null | string;
  setInitialValues: Dispatch<SetStateAction<Record<string, string>>>;
  setVendorName: Dispatch<SetStateAction<null | string>>;
  setCurrentFormName: Dispatch<SetStateAction<null | string>>;
  setEmailList: Dispatch<SetStateAction<null | string>>;
  setIsFeatherFormModalOpen: Dispatch<SetStateAction<boolean>>;
};
const FeatheryFormContext = createContext<Partial<FeatheryFormContextProps>>(
  {},
);
FeatheryFormContext.displayName = 'FeatheryFormContext';

const FeatheryFormProvider = (props: { children: ReactNode }) => {
  init(process.env.GATSBY_FEATHERY_API_KEY as string);
  const [currentFormName, setCurrentFormName] = useState<null | string>(null);
  const [emailList, setEmailList] = useState<null | string>(null);
  const [vendorName, setVendorName] = useState<null | string>(null);
  const [isFeatheryFormModalOpen, setIsFeatherFormModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<Record<string, string>>(
    {},
  );

  useEffect(() => {
    if (!isFeatheryFormModalOpen) {
      setCurrentFormName(null);
    }
  }, [isFeatheryFormModalOpen]);
  const values: FeatheryFormContextProps = {
    currentFormName,
    emailList,
    setEmailList,
    setCurrentFormName,
    isFeatheryFormModalOpen,
    setIsFeatherFormModalOpen,
    setInitialValues,
    initialValues,
    vendorName,
    setVendorName,
  };
  return <FeatheryFormContext.Provider value={values} {...props} />;
};

/**
 * @description
 * A hook responsible for interacting with and retrieving data from FeatheryFormContext.
 */
function useFeatheryForm(): FeatheryFormContextProps {
  const context = useContext(FeatheryFormContext);
  // if (context === undefined || Object.keys(context).length === 0) {
  //   throw new Error(
  //     `useFeatheryForm must be used within a FeatheryFormProvider`,
  //   );
  // }
  return context as FeatheryFormContextProps;
}

export { FeatheryFormProvider, useFeatheryForm };
