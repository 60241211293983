import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const queryClient = new QueryClient();

export const RootElement = ({ children }: { children: ReactNode }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITEKEY!}
      useEnterprise={true}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'body',
      }}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
};
